import { Injectable } from '@angular/core';
import { User }  from '../models/user.model';
import { Lookup }  from '../models/lookup.model';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable()
export class AppDataProvider {
  public user: User;
  public lookupData: Array<Lookup>;

  constructor(private domSanitizer: DomSanitizer) {

  }

  setUser(user: User) {
    this.user = user;
  }

  setLookupData(data) {
    this.lookupData = data;
  }


  getLandingPage() {
    // if(localStorage.getItem('firstStart') == null) {
    //   localStorage.setItem('firstStart', '0');
    //   return '/welcome';
    // }
    // else
    //   return '/login';
  }

  getSafeProfileImage(picture) {
    if((picture != undefined) && (picture != null) && (picture != ''))
      return this.domSanitizer.bypassSecurityTrustResourceUrl(picture);
    else
      return '/assets/avatar.png'
  }

  getSafeImage(picture) {
    if((picture != undefined) && (picture != null) && (picture != ''))
      return this.domSanitizer.bypassSecurityTrustResourceUrl(picture);
    else
      return '/assets/placeholder.png'
  }
  

}
