import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { IonicRatingComponentModule } from 'ionic-rating-component';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AppDataProvider } from './app.data.provider';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'md' }),
    AppRoutingModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDIJwOBfrSgIuO82i0mDGIJC_iGUGPOYrY',
      libraries: ['places'],
    }),
    // IonicRatingComponent, IonicRatingComponentModule, IonicRatingService
    IonicRatingComponentModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AppDataProvider,
    SplashScreen,
    Geolocation,
    NativeGeocoder,
    SocialSharing,
    PhotoViewer,
    CallNumber,
    InAppBrowser,
    MobileAccessibility
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
