import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import {
  ActionSheetController,
  AlertController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { Location } from '@angular/common';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AppGlobals } from './app.globals';
import { AppDataProvider } from './app.data.provider';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [];

  constructor(
    private router: Router,
    public commonService: CommonService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private modalController: ModalController,
    private alertController: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private location: Location,
    private navCtrl: NavController,
    private appDataProvider: AppDataProvider,
    private http: HttpClient,
    private mobileAccessibility: MobileAccessibility,
  ) {
    this.mobileAccessibility.setTextZoom(100);
    this.mobileAccessibility.updateTextZoom();
    this.mobileAccessibility.usePreferredTextZoom(false);

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);

      /* ES ENABLE THIS ON DEVICE */
      window['plugins'].OneSignal.setLogLevel({ logLevel: 6, visualLevel: 0 });

      let that = this;
      var notificationReceived = function (jsonData) {
        that.loadUserInfo();
      };

      var iosSettings = {};
      iosSettings['kOSSettingsKeyAutoPrompt'] = false;
      iosSettings['kOSSettingsKeyInAppLaunchURL'] = false;

      window['plugins'].OneSignal.startInit(AppGlobals.ONE_SIGNAL_KEY)
        .handleNotificationOpened(notificationReceived)
        .handleNotificationReceived(notificationReceived)
        .iOSSettings(iosSettings)
        .inFocusDisplaying(
          window['plugins'].OneSignal.OSInFocusDisplayOption.Notification,
        )
        .endInit();

      window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse(
        function (accepted) {
          if (!accepted) {
            this.showMessage(
              'Warning',
              'This application requires push notifications in order to get alerts for receiving job quotes and status updates. Disabling push notifications are not recommended. To enable please go to your phone settings, go to our app and enable push notifications.',
            );
          }
        },
      );

      window['plugins'].OneSignal.getIds(function (ids) {
        localStorage.setItem('deviceid', ids.userId);
      });
    });

    try {
      this.platform.backButton.subscribeWithPriority(999, async () => {
        console.log(' clicked backbutton on appcomponent event');

        const modal = await this.modalController.getTop();
        if (modal) {
          await this.modalController.dismiss();
          return;
        }

        const alert = await this.alertController.getTop();
        if (alert) {
          await alert.dismiss();
          return;
        }

        const actionSheetCtrl = await this.actionSheetCtrl.getTop();
        if (actionSheetCtrl) {
          await actionSheetCtrl.dismiss();
          return;
        }
        if (
          this.router.url.includes('/login') ||
          this.router.url.includes('/tabs/home')
        ) {
          console.log('Do you want close app?');
          // @ts-ignore
          navigator.app.exitApp();
        } else if (
          this.router.url.includes('/tabs/') &&
          !this.router.url.includes('/tabs/home')
        ) {
          console.log('-----homepage----');
          this.location.back();
        } else {
          console.log('-----subpage----');
          this.navCtrl.back();
        }
      });
    } catch (err) {
      console.log('This is normal in a browser', err);
    }
  }

  async logout() {
    localStorage.clear();
    this.appPages = this.commonService.hunterPages;
    await this.router.navigate(['/sign-in']);
  }

  selectProfileType(title: string) {
    if (title === 'My Hunting Profile') {
      localStorage.setItem('profileType', 'hunter');
    } else {
      localStorage.setItem('profileType', 'farmer');
    }
  }

  ngOnInit() {
    this.authenticateUser();
  }

  authenticateUser() {
    var authData = {
      deviceid: localStorage.getItem('deviceid'),
      devicename: 'Unknown',
    };

    if (!localStorage.getItem('userid')) this.router.navigate(['/sign-in']);
    else {
      if (
        localStorage.getItem('deviceid') == null ||
        localStorage.getItem('deviceid') == ''
      ) {
        // this.commonService.showMessage(
        //   'Warning',
        //   'Device not registered for push notifications. This app will not function correctly without notifcations enabled and allowed for this app.',
        // );
      }

      const headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('x-access-token', localStorage.getItem('token'));

      this.http
        .post<any>(AppGlobals.URL + 'auth/me', authData, { headers })
        .subscribe(
          async data => {
            if (data.userroleid == '1') {
              this.appPages = this.commonService.hunterPages;
            } else if (data.userroleid == '2') {
              this.appPages = this.commonService.advertiserPages;
            } else {
              this.appPages = this.commonService.hunterAndAdvertiserPages;
            }
            this.loadLookupData();

            this.appDataProvider.setUser(data);

            this.router.url.includes('/tabs/home');
          },
          async err => {
            this.router.navigate(['/sign-in']);
          },
        );
    }
  }

  loadUserInfo() {
    var authData = {
      deviceid: localStorage.getItem('deviceid'),
      devicename: 'Unknown',
    };
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', localStorage.getItem('token'));

    this.http
      .post<any>(AppGlobals.URL + 'auth/me', authData, { headers })
      .subscribe(
        async data => {
          this.appDataProvider.setUser(data);

          setTimeout(() => {
            this.router.url.includes('/tabs/home');
          }, 1000);
        },
        async err => {},
      );
  }

  loadLookupData() {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-access-token', localStorage.getItem('token'));
    this.http.get<any>(AppGlobals.URL + 'lookups', { headers }).subscribe(
      async data => {
        this.appDataProvider.setLookupData(data);
      },
      async err => {},
    );
  }
}
