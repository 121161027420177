import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./sign-in/sign-in.module').then(m => m.SignInPageModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then(m => m.RegisterPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordPageModule,
      ),
  },
  {
    path: 'my-ads',
    loadChildren: () =>
      import('./my-ads/my-ads.module').then(m => m.MyAdsPageModule),
  },
  {
    path: 'my-weapons',
    loadChildren: () =>
      import('./my-weapons/my-weapons.module').then(m => m.MyWeaponsPageModule),
  },
  {
    path: 'browse-auctions',
    loadChildren: () =>
      import('./browse-auctions/browse-auctions.module').then(
        m => m.BrowseAuctionsPageModule,
      ),
  },
  {
    path: 'my-profile',
    loadChildren: () =>
      import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule),
  },
  {
    path: 'browse-weapons',
    loadChildren: () =>
      import('./browse-weapons/browse-weapons.module').then(
        m => m.BrowseWeaponsPageModule,
      ),
  },
  {
    path: 'browse-ads',
    loadChildren: () =>
      import('./browse-ads/browse-ads.module').then(m => m.BrowseAdsPageModule),
  },
  {
    path: 'browse-hunting-photos',
    loadChildren: () =>
      import('./browse-hunting-photos/browse-hunting-photos.module').then(
        m => m.BrowseHuntingPhotosPageModule,
      ),
  },
  {
    path: 'my-favourites',
    loadChildren: () =>
      import('./my-favourites/my-favourites.module').then(
        m => m.MyFavouritesPageModule,
      ),
  },
  {
    path: 'search-results',
    loadChildren: () =>
      import('./search-results/search-results.module').then(
        m => m.SearchResultsPageModule,
      ),
  },
  {
    path: 'map',
    loadChildren: () => import('./map/map.module').then(m => m.MapPageModule),
  },
  {
    path: 'species',
    loadChildren: () =>
      import('./modals/species/species.module').then(m => m.SpeciesPageModule),
  },
  {
    path: 'booking-request',
    loadChildren: () =>
      import('./booking-request/booking-request.module').then(
        m => m.BookingRequestPageModule,
      ),
  },
  {
    path: 'specify-hunters',
    loadChildren: () =>
      import('./modals/specify-hunters/specify-hunters.module').then(
        m => m.SpecifyHuntersPageModule,
      ),
  },
  {
    path: 'date-picker',
    loadChildren: () =>
      import('./modals/date-picker-modal/date-picker-modal.module').then(
        m => m.DatePickerModalPageModule,
      ),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./notifications/notifications.module').then(
        m => m.NotificationsPageModule,
      ),
  },
  {
    path: 'view-booking-request',
    loadChildren: () =>
      import('./view-booking-request/view-booking-request.module').then(
        m => m.ViewBookingRequestPageModule,
      ),
  },
  {
    path: 'view-hunters',
    loadChildren: () =>
      import('./view-hunters/view-hunters.module').then(
        m => m.ViewHuntersPageModule,
      ),
  },
  {
    path: 'rate-farm-hunting',
    loadChildren: () =>
      import('./rate-farm-hunting/rate-farm-hunting.module').then(
        m => m.RateFarmHuntingPageModule,
      ),
  },
  {
    path: 'view-experience',
    loadChildren: () =>
      import('./view-experience/view-experience.module').then(
        m => m.ViewExperiencePageModule,
      ),
  },
  {
    path: 'view-hunter-profile',
    loadChildren: () =>
      import('./view-hunter-profile/view-hunter-profile.module').then(
        m => m.ViewHunterProfilePageModule,
      ),
  },
  {
    path: 'my-wildlife-auctions',
    loadChildren: () =>
      import('./my-wildlife-auctions/my-wildlife-auctions.module').then(
        m => m.MyWildlifeAuctionsPageModule,
      ),
  },
  {
    path: 'view-game',
    loadChildren: () =>
      import('./view-game/view-game.module').then(m => m.ViewGamePageModule),
  },
  {
    path: 'loading-page',
    loadChildren: () =>
      import('./loading-page/loading-page.module').then(
        m => m.LoadingPagePageModule,
      ),
  },
  {
    path: 'view-accommodation',
    loadChildren: () =>
      import('./view-accommodation/view-accommodation.module').then(
        m => m.ViewAccommodationPageModule,
      ),
  },
  {
    path: 'view-ratings',
    loadChildren: () =>
      import('./view-ratings/view-ratings.module').then(
        m => m.ViewRatingsPageModule,
      ),
  },
  {
    path: 'filter-search-results',
    loadChildren: () =>
      import(
        './modals/filter-search-results/filter-search-results.module'
      ).then(m => m.FilterSearchResultsPageModule),
  },
  {
    path: 'sort-search-results',
    loadChildren: () =>
      import('./modals/sort-search-results/sort-search-results.module').then(
        m => m.SortSearchResultsPageModule,
      ),
  },
  {
    path: 'filter-auctions',
    loadChildren: () =>
      import('./modals/filter-auctions/filter-auctions.module').then(
        m => m.FilterAuctionsPageModule,
      ),
  },
  {
    path: 'sort-auctions',
    loadChildren: () =>
      import('./modals/sort-auctions/sort-auctions.module').then(
        m => m.SortAuctionsPageModule,
      ),
  },
  {
    path: 'filter-weapons',
    loadChildren: () =>
      import('./modals/filter-weapons/filter-weapons.module').then(
        m => m.FilterWeaponsPageModule,
      ),
  },
  {
    path: 'sort-weapons',
    loadChildren: () =>
      import('./modals/sort-weapons/sort-weapons.module').then(
        m => m.SortWeaponsPageModule,
      ),
  },
  {
    path: 'filter-ads',
    loadChildren: () =>
      import('./modals/filter-ads/filter-ads.module').then(
        m => m.FilterAdsPageModule,
      ),
  },
  {
    path: 'sort-ads',
    loadChildren: () =>
      import('./modals/sort-ads/sort-ads.module').then(
        m => m.SortAdsPageModule,
      ),
  },
  {
    path: 'filter-photos',
    loadChildren: () =>
      import('./modals/filter-photos/filter-photos.module').then(
        m => m.FilterPhotosPageModule,
      ),
  },
  {
    path: 'sort-photos',
    loadChildren: () =>
      import('./modals/sort-photos/sort-photos.module').then(
        m => m.SortPhotosPageModule,
      ),
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import('./terms-of-use/terms-of-use.module').then(
        m => m.TermsOfUsePageModule,
      ),
  },
  {
    path: 'paia-and-popia',
    loadChildren: () =>
      import('./paia-and-popia/paia-and-popia.module').then(
        m => m.PaiaAndPopiaPageModule,
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then(
        m => m.PrivacyPolicyPageModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
